import React, { useState } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';


function Counter({ name, value, color }) {
  return (
    <div className={`counter ${name}`} style={{ '--counter-bg-color': color }}>
      <span>{name}: {value}</span>
    </div>
  );
}




function Grid() {
  const counters = [
    { name: "A1", position: "vertical", gridColumn: 12, gridRow: 2, color: "#99cc33" },
    { name: "A2", position: "vertical", gridColumn: 11, gridRow: 6, color: "#9bddff" },
    { name: "A3", position: "vertical", gridColumn: 10, gridRow: 8, color: "#f36c60" },
    { name: "A4", position: "horizontal", gridColumn: 9, gridRow: 11, color: "#ffa700" },
    { name: "A5", position: "horizontal", gridColumn: 6, gridRow: 11, color: "#ffffff" },
    { name: "A6", position: "vertical", gridColumn: 4, gridRow: 9, color: "#f36c60" },
    { name: "A7", position: "vertical", gridColumn: 3, gridRow: 7, color: "#ff9966" },
    { name: "A8", position: "vertical", gridColumn: 2, gridRow: 5, color: "#ffcc00" },
    { name: "A9", position: "vertical", gridColumn: 1, gridRow: 2, color: "#99cc33" },

    { name: "D1", position: "vertical", gridColumn: 11, gridRow: 2, color: "#99cc33" },
    { name: "D2", position: "vertical", gridColumn: 10, gridRow: 5, color: "#9bddff" },
    { name: "D3", position: "vertical", gridColumn: 9, gridRow: 7, color: "#f36c60" },
    { name: "D4", position: "horizontal", gridColumn: 9, gridRow: 10, color: "#ffa700" },
    { name: "D5", position: "horizontal", gridColumn: 6, gridRow: 10, color: "#ffffff" },
    { name: "D6", position: "vertical", gridColumn: 5, gridRow: 8, color: "#f36c60" },
    { name: "D7", position: "vertical", gridColumn: 4, gridRow: 6, color: "#ff9966" },
    { name: "D8", position: "vertical", gridColumn: 3, gridRow: 4, color: "#ffcc00" },
    { name: "D9", position: "vertical", gridColumn: 2, gridRow: 2, color: "#99cc33" },
  ];

  const [counterValues, setCounterValues] = useState({
    "A1": 5, "A2": 5, "A3": 5, "A4": 5, "A5": 5, "A6": 5, "A7": 5, "A8": 5, "A9": 5,
    "D1": 5, "D2": 5, "D3": 5, "D4": 5, "D5": 5, "D6": 5, "D7": 5, "D8": 5, "D9": 5
  });

  const handleIncrement = (name) => {
    setCounterValues(prevValues => ({
      ...prevValues,
      [name]: Math.min(prevValues[name] + 1, 20)
    }));
  };

  const handleDecrement = (name) => {
    setCounterValues(prevValues => ({
      ...prevValues,
      [name]: Math.max(prevValues[name] - 1, 1)
    }));
  };

  return (
    <div className="grid-container">
      {counters.map((counter) => {
        const isVertical = counter.position === "vertical";
        return (
          <React.Fragment key={counter.name}>
            {isVertical && <div style={{ gridColumn: counter.gridColumn, gridRow: counter.gridRow - 1, backgroundColor: counter.color }}><button className="button increment" onClick={() => handleIncrement(counter.name)}>+</button></div>}
            {!isVertical && <div style={{ gridColumn: counter.gridColumn - 1, gridRow: counter.gridRow, backgroundColor: counter.color }}><button className="button decrement" onClick={() => handleDecrement(counter.name)}>-</button></div>}

            <div style={{ gridColumn: counter.gridColumn, gridRow: counter.gridRow }}>
              <Counter name={counter.name} position={counter.position} value={counterValues[counter.name]} color={counter.color} />
            </div>

            {isVertical && <div style={{ gridColumn: counter.gridColumn, gridRow: counter.gridRow + 1, backgroundColor: counter.color }}><button className="button decrement" onClick={() => handleDecrement(counter.name)}>-</button></div>}
            {!isVertical && <div style={{ gridColumn: counter.gridColumn + 1, gridRow: counter.gridRow, backgroundColor: counter.color }}><button className="button increment" onClick={() => handleIncrement(counter.name)}>+</button></div>}
          </React.Fragment>
        );
      })}
    </div>
  );
}



function App() {
  return (
    <div className="App">
      <Grid />
    </div>
  );
}

export default App;
